<template>
    <div>
        
        <CRow v-if="!change_view">
            <CCol lg="1" md="1" s="1" xs="1">
                <CButtonGroup>
                  <CButton 
                    @click="myViewPreference('tableView')"
                    :color="view_preference == 'tableView' ? 'primary' : 'light'" 
                    v-c-tooltip="{content:'View your Documents as Table View', placement: 'top'}"
                  >
                    <font-awesome-icon icon="th-list"/>
                  </CButton>
                  <CButton 
                    @click="myViewPreference('cardView')"
                    :color="view_preference == 'cardView' ? 'primary' : 'light'" 
                    v-c-tooltip="{content:'View your Documents as Card View', placement: 'top'}"
                  >
                    <font-awesome-icon icon="th"/>
                  </CButton>
                </CButtonGroup>
            </CCol>
            <CCol lg="4" md="4"  sm="4" xs="4">
                <!-- <VCalendar/> -->
            </CCol>
            <CCol lg="1" md="1" sm="1" xs="1">
                <CButton
                    class="float-right"
                    color="primary"
                    title="Advance Filter"
                    @click="advanceFilter()"
                >
                    <font-awesome-icon icon="sliders-h"/>
                </CButton>
            </CCol>
            <CCol lg="4" md="4" sm="4" xs="4">
                <CInput 
                    placeholder="Enter Reference Number"
                    type="text" 
                    autocomplete="off" 
                    v-tocapitalize
                    v-nospecialcharacter
                    v-model="to_search.reference_number"
                    v-on:keyup.enter="searchData"
                >
                <template #append>
                    <CButton 
                        color="primary"
                        @click="searchData()"
                    >
                        <font-awesome-icon icon="search"/>
                    </CButton>
                </template>
                </CInput>
            </CCol>
            <CCol lg="2" md="2" sm="2" xs="2">
                <CButton
                    class="float-right"
                    color="warning"
                    title="Advance Filter"
                    @click="clearSearch()"
                >
                    <font-awesome-icon icon="eraser"/> Remove Filter
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
                <CTabs justified variant="pills" :active-tab="current_tab" @update:activeTab="changeTab" >
                    <CTab title="Draft" :disabled="!list_loaded"/>
                    <CTab title="For Signing" :disabled="!list_loaded"/>
                    <CTab title="On Process" :disabled="!list_loaded"/>
                    <CTab title="Completed" :disabled="!list_loaded"/>
                    <CTab title="Cancelled" :disabled="!list_loaded"/>
                </CTabs>
            </CCol>
        </CRow>
        <br>
        <DRTabsLists :viewPreference='view_preference' ref="document_lists" @LIST_TO_DETAILS="detailsView($event)" v-if="!change_view" @LIST_LOADED="listLoaded()"/>
        <DRTabsListsDetails ref="document_details" @DETAILS_TO_LIST="listsView($event)" @LIST_LOADED="listLoaded()" @dynamicChangeTab="changeTabFromChild($event)" v-if="change_view"/>
        <CModal
            :show.sync="custom_filter"
            color="primary"
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5> <font-awesome-icon icon="sliders-h"/> Advance Filter </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="custom_filter = false"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="12">
                            <label>
                                Select Date Range:
                            </label>
                            <CDateRange ref="_CDateRange"/>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <label> Document Creator  </label>
                            <v-select
                                :disbled="users.length < 0"
                                :options="users"
                                label="first_name"
                                v-model="to_search.account_manager"
                            >
                                <template #list-header>
                                    <li style="text-align: center">Search by first name</li>
                                </template>
                                <template v-slot:option="d_name">
                                    {{ d_name.first_name }} {{ d_name.last_name }} {{ d_name.suffix }}
                                </template>
                                <template v-slot:selected-option="s_name">
                                    {{ s_name.first_name }} {{ s_name.last_name }}{{ s_name.suffix }}
                                </template>
                            </v-select>
                        </CCol>
                    </CRow>
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <label> Document Category </label>
                            <v-select
                                :disbled="templates.length < 0"
                                :options="templates"
                                label="category"
                                v-model="to_search.template"
                            >
                            </v-select>
                        </CCol>
                    </CRow>
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <CButton
                                :disabled="submit_advance_filter"
                                class="float-right"
                                color="primary"
                                @click="!submit_advance_filter ? searchDataAdvanced() : ''"
                            >
                                <font-awesome-icon 
                                    :icon="!submit_advance_filter ? 'share-square' : 'circle-notch'"
                                    :pulse="submit_advance_filter"
                                /> Search
                            </CButton>
                        </CCol>
                    </CRow>
                </CCardBody>

            </template>
            <template #footer>
                <hr hide>
            </template>

        </CModal>
    </div>
</template>
<script>

import DRTabsLists from '../document-routing/DRTabsLists'
import DRTabsListsDetails from '../document-routing/DRTabsListsDetails'
import CDateRange from '../../components/CDateRange'
export default {
    name: 'DocumentRoutingsTab',
    components: {
        DRTabsLists,
        DRTabsListsDetails,
        CDateRange,
    },
    data() {
        return {
            view_preference: 'tableView',
            custom_filter: false,
            current_tab: null,
            selected_document: null,
            document_routing_id: null,
            submit_advance_filter: false,
            users: [],
            templates: [],
            to_search : {
                reference_number: null,
                account_manager: null,
                template: null,
                date_from: null,
                date_to: null,
            },
            change_view: this.$route.query.reference_id != undefined ? true : false,
            list_loaded: false,
        }
    },
    created() {
        this.$emit('activeTab', 0);
        if(this.change_view) {
            this.current_tab = this.$route.query.tab == 'signing-list' ? 1 
                                : this.$route.query.tab == 'processing-list' ? 2 
                                    : this.$route.query.tab == 'completed-list' ? 3 
                                        : this.$route.query.tab == 'cancelled-list' ? 4 
                                            : 0
        } else {
            this.$nextTick(() => {
                let status = this.$route.query.tab ? this.$route.query.tab : 'draft-list'
                this.$refs.document_lists.slug = status
                this.$refs.document_lists.getDocuments();
                this.current_tab = this.$route.query.tab == 'signing-list' ? 1 
                                    : this.$route.query.tab == 'processing-list' ? 2 
                                        : this.$route.query.tab == 'completed-list' ? 3 
                                            : this.$route.query.tab == 'cancelled-list' ? 4 
                                                : 0
                                                
            })
        }
    },
    methods: {
        myViewPreference: function(value) {
            this.view_preference = value
        },
        listLoaded: function() {
            this.list_loaded = true;
        },
        clearSearch: function () {

            

            this.to_search.reference_number = null,
            this.to_search.account_manager = null,
            this.to_search.template = null,
            this.to_search.date_from = null,
            this.to_search.date_to = null,
            this.$refs.document_lists.dashboard_reference_number = null
             
            this.$refs._CDateRange.range = null

            this.$refs.document_lists.getDocuments();
        },
        advanceFilter: function () {
            this.$Progress.start();
            this.getUsers();
            this.getTemplates();
            
            // Empty the reference number
            this.to_search.reference_number = null
            this.custom_filter = true;
            this.$refs.document_lists.dashboard_reference_number = null
            // let today = new Date();
            // let minus2_month = today.setMonth(today.getMonth() - 1); // Today - 1 Month
            // let object = {
            //     end: new Date(),
            //     start: new Date(minus2_month)
            // }
            // this.$refs._CDateRange.range = object

        },
        searchDataAdvanced: function ()  {
            this.list_loaded = false
            this.submit_advance_filter = true;

            let to_search = {}

            if((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end)){
                this.to_search.date_from = this.$refs._CDateRange.range.start.getFullYear() + "-" + ("0"+(this.$refs._CDateRange.range.start.getMonth()+1)).slice(-2) +"-"+("0" + this.$refs._CDateRange.range.start.getDate()).slice(-2);
                this.to_search.date_to = this.$refs._CDateRange.range.end.getFullYear() + "-" + ("0"+(this.$refs._CDateRange.range.end.getMonth()+1)).slice(-2) +"-"+("0" + this.$refs._CDateRange.range.end.getDate()).slice(-2);
                to_search.date_from = this.to_search.date_from;
                to_search.date_to = this.to_search.date_to;
            }


            if(this.to_search.account_manager) {
                to_search.user_id = this.to_search.account_manager.id
            }
            if(this.to_search.template) {
                to_search.document_template_category_id = this.to_search.template.id
            }
            
            this.$refs.document_lists.current_page = 1
            this.$refs.document_lists.slug = this.current_tab == 0 ? 'draft-list' 
                                                : this.current_tab == 1 ? 'signing-list'
                                                    : this.current_tab == 2 ? 'processing-list'
                                                        : this.current_tab == 3 ? 'completed-list' : 'cancelled-list'

            this.$refs.document_lists.getDocuments(to_search);
        },
        searchData: function () {
            // Empty advance search data
            this.to_search.account_manager = null
            this.to_search.template = null
            this.to_search.date_from = null
            this.to_search.date_to = null

            this.change_view = false;
            this.$emit('emit_search', );

            this.$refs.document_lists.current_page = 1
            this.$refs.document_lists.slug = this.current_tab == 0 ? 'draft-list' 
                                                : this.current_tab == 1 ? 'signing-list'
                                                    : this.current_tab == 2 ? 'processing-list'
                                                        : this.current_tab == 3 ? 'completed-list' : 'cancelled-list'

            this.$refs.document_lists.getDocuments(this.to_search);
        },
        changeTab: function(item) {
            this.list_loaded = false
            if(this.$route.query.tab != undefined) {
                this.$router.push(this.$route.path)
                this.change_view = false
            }
            this.current_tab = item;
            this.change_view = false

            
             this.to_search.reference_number = null,
             this.to_search.account_manager = null,
             this.to_search.template = null,
             this.to_search.date_from = null,
             this.to_search.date_to = null,

            this.$refs._CDateRange.range = null

            this.$nextTick(() => {
                let status = (item == 0) ? 'draft-list' : 
                    (item == 1) ? 'signing-list' :
                    (item == 2) ? 'processing-list' :
                    (item == 3) ? 'completed-list' :
                    'cancelled-list';
                this.$refs.document_lists.dashboard_reference_number = null
                this.$refs.document_lists.slug = status
                this.$refs.document_lists.current_page = 1
                this.$refs.document_lists.getDocuments();
            })
        },
        detailsView: function(object) {
            this.list_loaded = false
            this.change_view = true;
            this.$nextTick(function() {
                this.$refs.document_details.routing.id = object.id
                this.$refs.document_details.parent_tab = this.current_tab
                this.$refs.document_details.parent_tab_current_page = object.current_page
                this.$refs.document_details.getRoutingDetails();
            })

        },
        listsView: function (object) {
            this.list_loaded = false
            this.change_view = false;
            this.$nextTick(function() {
                let data =  {}
                
                if(this.to_search.date_from && this.to_search.date_to) {
                    data.date_from = this.to_search.date_from
                    data.date_to = this.to_search.date_to
                }
                if(this.to_search.reference_number) {
                    data.reference_number = this.to_search.reference_number
                }
                if(this.to_search.account_manager) {
                    data.user_id = this.to_search.account_manager.id
                }
                if(this.to_search.template) {
                    data.document_template_id = this.to_search.template.id
                }
                this.$refs.document_lists.slug = (this.current_tab == 0) ? 'draft-list' : 
                                                    (this.current_tab == 1) ? 'signing-list' :
                                                        (this.current_tab == 2) ? 'processing-list' :
                                                            (this.current_tab == 3) ? 'completed-list' :
                                                                'cancelled-list';
                this.$refs.document_lists.current_page = object.current_page
                this.$refs.document_lists.getDocuments(data);
            });
            
        },
        changeTabFromChild: function(object) {
            this.current_tab = object.current_tab;
            this.$refs.document_details.parent_tab = this.current_tab
            this.$refs.document_details.parent_tab_current_page = object.current_page
        },
        getUsers:function () {
            axios.get('/users/active-list',  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.users = response.data.data
                    this.$Progress.increase(50);
                }
            })
        },
        getTemplates:function () {
            axios.get('/drs/document-template-category/active-list',  {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.templates = response.data.data
                    this.$Progress.finish();
                }
            })
        }
    },
    watch: {
        current_tab: function (value) {
            if(this.$route.query.reference_id != undefined) {
                this.change_view = true;
                this.$nextTick(function() {
                    this.$refs.document_details.routing.id = this.$route.query.reference_id
                    this.$refs.document_details.parent_tab = 1
                    this.$refs.document_details.parent_tab_current_page = 1
                    this.$refs.document_details.getRoutingDetails();
                })
            }
        },
        '$route' (to, from) {
            if(to.query.tab != undefined) {
                this.current_tab = (to.query.tab == 'signing-list') ? 1 
                    : (to.query.tab == 'processing-list') ? 2 
                        : (to.query.tab == 'completed-list') ? 3 
                            : (to.query.tab == 'cancelled-list') ? 4 
                                : this.current_tab;
            }
            
            if(to.query.tab != undefined && to.query.reference_id != undefined) {
                this.change_view = true;
                this.$nextTick(function() {
                    this.$refs.document_details.routing.id = this.$route.query.reference_id
                    this.$refs.document_details.parent_tab = 1
                    this.$refs.document_details.parent_tab_current_page = 1
                    this.$refs.document_details.getRoutingDetails();
                })
            }

        },
        list_loaded: function(value) {
            if(value) {
                this.submit_advance_filter = false;
                this.custom_filter = false;
            }
        }
    }
}
</script>